html {
  height: 100%;
  width: 100%;
}

body {
  background-color: #282c34;
  margin: 0px;
  padding: 0px;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 90%;
  margin: 0 auto;
}

.App h3 {
  color: #fff;
  text-transform: uppercase;
}

.header {
}

.header a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 10px;
  padding-top: 20px;
  display: block;
}

.content {
  position: relative;
  overflow: hidden;
  height: 65vh;
  overflow: scroll;
}

.help-menu {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

button {
  width: 100%;
  display: block;
  margin: 0 auto;
  border: 1px solid #ccc;
  font-size: 16px;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 8px 0px;
  text-align: left;
  padding-left: 20px;
  box-sizing: border-box;
  color: black;
  text-transform: uppercase;
}

button.back {
  font-weight: bold;
  text-align: right;
  padding-right: 20px;
  padding-left: 0;
}

span.forward {
  float: right;
  padding-right: 20px;
}

span.backward {
  float: left;
  padding-left: 20px;
}

.report-start {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ccc;
  text-align: justify;
  box-sizing: border-box;
  padding: 30px;
  color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  line-height: 1.5em;
  font-size: 15px;
}

.report-start p {
  margin: 0;
}

.report-done {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ccc;
  text-align: center;
  box-sizing: border-box;
  padding: 30px;
  color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
}

.report-done h2 {
  font-size: 30px;
  margin-bottom: 10px;
}

.report-done p {
  font-size: 20px;
  margin-bottom: 30px;
}

.footer {
  text-align: left;
}

.footer .inner {
  margin: 0 auto;
  position: relative;
  padding-bottom: 10px;
}

p.about {
  text-align: right;
  width: 70%;
  color: #fff;
  box-sizing: border-box;
  font-size: 12px;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

p.about a {
  color: #fff;
  text-decoration: underline;
}

.quadrat {
  animation: blinking 1s infinite;  /* IE 10+, Fx 29+ */
}

@keyframes blinking {
  0%, 49% {
    background-color: white;
  }
  50%, 100% {
    background-color: red;
  }
}

.warn-sign {
  margin: 0 0;
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  padding: 5px;
  color: black;
  font-size: 12px;
  font-weight: bold;
}

.warn-sign.active {
  background-color: green;
}